import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import React from 'react';

// Pages
import Home from './pages/home';
// import About from './pages/about';

// Components
// import Header from './components/Header';
import Footer from './components/Footer';
import Navigation from './components/Navigation';

// App
function App() {
  return (
    <>
      <Navigation />
      <Home />
      <Footer />
    </>
    // Or for multi-page websites (might need to adjust the homepage value in package.json)
    // <Router>
    //   <Navigation/>
    //   <Routes>
    //     <Route exact path='./' element={<Home />} />
    //     <Route path='/about' element={<About/>} />
    //   </Routes>
    //   <Footer />
    // </Router>
  );
}

export default App;
