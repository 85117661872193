import './../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import Data from "../resources/carousel.json";

const styles = {
  card: {
    backgroundColor: '#B7E0F2',
    borderRadius: 55,
    padding: '3rem'
  },
  cardImage: {
    objectFit: 'cover',
    // borderRadius: 55,
    width: '18rem',
    height: '60vh'
  }
}

const IntroCarousel = () => {
  return (
    <Carousel>
      { Data.map(post =>
         <Carousel.Item>
           <img
             style={styles.cardImage}
             className="d-block w-100" // h-20
             src={post.image}
             alt="First slide"
           />
           <Carousel.Caption>
           <h1>{post.title}</h1>
           <h2>{post.description}</h2>
           </Carousel.Caption>
         </Carousel.Item>
      )}
    </Carousel>
  )
};

export default IntroCarousel;
