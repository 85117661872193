
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Col, Row } from 'react-bootstrap';
import Data from "../resources/projects.json";

const styles = {
  cardText: {
  },
  cardImage: {
    objectFit: 'cover',
    height: '30vh'
  }
}

const Projects = () => {
  const nCols = 3;
  return (
    <Row xs={2} md={nCols} >
      { Data.map(post =>
        <Col key={post.id} className="col-md-3 g-2">
          <Card className="h-100">
            {post.video ?
              <div>
                <iframe
                  style={styles.cardImage}
                  className="d-block w-100 h-20"
                  src="https://www.youtube.com/embed/GcAitzDaQwg?start=15"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                  allowFullScreen
                ></iframe>
              </div> :
              <Card.Img style={styles.cardImage} variant="top" src={process.env.PUBLIC_URL + post.image} />
            }
            <Card.Body>
              <Card.Title style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{post.title}</span>
                <span style={{ fontSize: 'small', fontWeight: 'bold', color: 'gray' }}>{post.year}</span>
              </Card.Title>
              <Card.Text style={styles.cardText}>
                {post.description}
              </Card.Text>
              {/* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
          </Card>
        </Col>
      )
      }
    </Row>
  )
};

export default Projects;



// const styles = {
//   cardText: {
//     // backgroundColor: '#B7E0F2',
//     // borderRadius: 55,
//     // padding: '3rem'
//   },
//   cardImage: {
//     objectFit: 'cover',
//     // borderRadius: 55,
//     //width: '18rem',
//     height: '30vh'
//   }
// }

// // TODO: make card and map over json file
// const Projects = () => {
//   const nCols = 3;
//   return (
//     <Row xs={2} md={nCols} >
//       { Data.map(post =>
//           <Col key={post.id} className="col-md-3 g-2">
//             <Card className="h-100">
//               <Card.Img style={styles.cardImage} variant="top" src={post.image}/>
//               <Card.Body>
//                 <Card.Title>{post.title}</Card.Title>
//                 <Card.Text style={styles.cardText}>{post.description}</Card.Text>
//                 {/* <Button variant="primary">Go somewhere</Button> */}
//               </Card.Body>
//             </Card>
//           </Col>
//         )
//       }
//       <Col key="0" className="col-md-3 g-2">
//         <Card className="h-100">
//           <div >
//             <iframe
//               style={styles.cardImage}
//               className="d-block w-100 h-20"
//               src="https://www.youtube.com/embed/GcAitzDaQwg?start=15"
//               title="YouTube video player"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
//               allowFullScreen
//             ></iframe>
//           </div>
//           <Card.Body>
//             <Card.Title>Audumbla 8</Card.Title>
//             <Card.Text style={styles.cardText}>Påbyggnad i centrala Umeå 2018</Card.Text>
//           </Card.Body>
//         </Card>
//       </Col>
//     </Row>
//   )
// };

// export default Projects;