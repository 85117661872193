import './../App.css';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Navigation() {
  const [activeLink, setActiveLink] = useState(null);

  const handleAnchorClick = (event, link) => {
    event.preventDefault();
    setActiveLink(link);

    const navbar = document.querySelector('.navbar');
    const headerOffset = navbar.offsetHeight;

    const elementId = link.substring(1);
    const element = document.getElementById(elementId);

    if (!element) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      return;
    }

    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };

  const scrollToTop = (event) => {
    event.preventDefault();
    setActiveLink(null);

    const navbar = document.querySelector('.navbar');
    const headerOffset = -navbar.offsetHeight;
    window.scrollTo({
      top: headerOffset,
      behavior: "smooth"
    });
  };

  const CustomNav = () => (
    <Nav className="me-auto">
      <Nav.Link
        className={`text-white ${activeLink === '#introduction' ? 'active' : ''}`}
        href="#introduction"
        onClick={(event) => handleAnchorClick(event, '#introduction')}
      >
        Hem
      </Nav.Link>
      <Nav.Link
        className={`text-white ${activeLink === '#about' ? 'active' : ''}`}
        href="#about"
        onClick={(event) => handleAnchorClick(event, '#about')}
      >
        Om
      </Nav.Link>
      <Nav.Link
        className={`text-white ${activeLink === '#contact' ? 'active' : ''}`}
        href="#contact"
        onClick={(event) => handleAnchorClick(event, '#contact')}
      >
        Kontakt
      </Nav.Link>
    </Nav>
  );

  return (
    <Navbar
      sticky="top"
      style={{ boxShadow: "5px 5px 5px lightgray" }}
      expand="lg"
      className="bg-primary text-white mb-3"
    >
      <Container>
        <Navbar.Brand onClick={scrollToTop}>
          <img alt="logo" src="./images/logo/logo.png" width="250px" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <CustomNav />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
