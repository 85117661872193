import './../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, Image, Row} from 'react-bootstrap';
import Employees from '../components/Employees';
import IntroCarousel from '../components/Carousel';
import Projects from '../components/Projects';
import React from 'react';

const Home = () => {
  return (
    <Container>
      {/* Carousel */}
      <section>
      <Row id="introCarousel" className="px-4 my-5">
        <IntroCarousel/>
      </Row>
      </section>

      {/* Introduction */}
      <section id="introduction" >
      <Row className="px-4 my-5">
        <h2>Välkommen</h2>
        <p>
          Vi är ett erfaret byggföretag med många genomförda projekt i Umeå och omnejd. Oavsett om
          det gäller nybyggnation, renovering eller ombyggnation av bostäder, kontor, ladugårdar och
          industrilokaler så kan vi hjälpa dig med dina byggbehov. Vi erbjuder även uthyrning av
          maskiner från markarbeten till finsnickeri.
        </p>
        <p>
          Vi har en gedigen erfarenhet av träbyggnationer och har genomfört många projekt där vi
          använt trä som byggmaterial. Trä är ett förnybart och miljövänligt material som ger en
          varm och naturlig känsla till ditt byggprojekt. Vi har bland annat byggt höghus i limträ
          på upp till 7 våningar. Med vår moderna teknik och expertis säkerställer vi högsta
          kvalitet i alla våra projekt.
        </p>
        <p>
          Kontakta oss idag för att diskutera dina byggbehov och hur vi kan hjälpa dig. Vi är din
          pålitliga partner inom byggbranschen och ser fram emot att arbeta tillsammans med dig.
        </p>
      </Row>
      </section>

{/*
      <Row id="contact8" className="px-4 my-5">
        <Card className="text-center bg-primary text-white my-5 py-4">
          <Card.Title>
            Kontakta Oss
          </Card.Title>
          <Card.Body>
            Gammelbyn 86<br />
            915 96 Flarken<br />
            info@arbygg.se
          </Card.Body>
        </Card>
      </Row> */}


      {/* Introduction */}
      <section id="ongoing">
      <Row className="px-4 my-5 g-4">
        <Col sm={6}>
          {/* <Image src="./images/trabalk.jpg" className="d-block w-500 h-20" fluid rounded/> */}
          <Image src="./images/frigg7.jpg" className="d-block w-500 h-20" fluid rounded/>
        </Col>
        <Col sm={6}>
          <h2>Aktuellt</h2>
          <p>
            Vi bygger för närvarande en påbyggnad med 22 högkvalitativa hyreslägenheter i det
            centrala kvarteret Frigg 7, även känt som Valandhuset, på Rådhusesplanaden 5–7. Som en
            del av Umehems hållbarhetsstrategi används limträ från Martinsons som stomme i hela
            påbyggnaden.
            {/* Vi bygger för närvarande 49 högkvalitativa hyreslägenheter i det centrala kvarteret
            Rind 6, som ligger nära Norrlandsoperan i Umeå, på uppdrag av Umehem. Som en del av
            Umehems hållbarhetsstrategi har limträ från Martinsons använts som stomme i hela
            byggnaden. Med undantag för det underjordiska garaget i betong och några stålkomponenter
            på plan ett, består hela byggnaden av trä. Trapphusen och hisschakten har helglasade
            väggar som gör det möjligt att se de vackra KL-träväggarna som sträcker sig längs
            byggnadens höjder. */}
          </p>
          {/* <Button variant="outline-primary">Call to action</Button> */}
        </Col>
      </Row>
      </section>

      <section id="speciality">
      <Row className="px-4 my-5">
        <Col sm={8}>
          <h2>Massivträ - vår specialitet</h2>
          <p>
            Genom att använda massiv trästomme kan vi bidra till att minska vår miljöpåverkan på
            flera sätt, jämfört med traditionell betongstomme som oftast används vid byggandet av
            flerbostadshus.
          </p>
          <p>
            En av de stora fördelarna med massivträ är att det lagrar koldioxid istället för att
            belasta atmosfären och bidra till växthuseffekten. En kubikmeter massivträ lagrar 675 kg
            koldioxid. Dessutom krävs det avsevärt mindre energi under produktionen jämfört med
            motsvarande betongstomme. Trä är helt naturligt, förnyelsebart och det enda
            byggmaterialet som kan återvinnas till 100%.
          </p>
          <p>
            Utöver de miljömässiga fördelarna ger massivträ en härlig och sund inomhusmiljö - det är
            fritt från skadliga ämnen, termiskt stabilt och jämnar ut fuktsvängningar. Massivträ är
            dessutom ett tätt material och är därför mer brandsäkert än många andra byggmaterial.
          </p>
          <p>
            Vi är övertygade om att det inte handlar om en kamp mellan stål, betong
            och trä, utan att den bästa lösningen är en kombination av olika material. Vi ser fram
            emot att fortsätta bygga en hållbar framtid tillsammans med våra kunder, där massivträ
            kan användas som ett innovativt och hållbart byggalternativ.
          </p>
        </Col>
        <Col sm={4}>
          <Image
            src="./images/painting.png"
            className="d-block w-100"
            style={{ maxWidth: '400px', maxHeight: '400px' }}
            fluid
            rounded
          />
        </Col>
      </Row>
      </section>

      {/* About */}
      <section id="about">
      <Row className="px-4 mb-5">
        <h2>Om</h2>
        <p>
          AR Bygg är ett etablerat byggföretag som grundades 1987 av Anders Lindgren i Umeå.
        </p>
        <p>
          Vår specialisering inom träbyggnation och höghus i limträ har gjort oss till en stark
          aktör i det nya framväxande Umeå.
        </p>
        <p>
          Sedan 2006 är vi en del av <a href="https://www.umehem.se">Umehem-koncernen</a>, som förvaltar
          80'000 kvadratmeter bostäder och kommersiella lokaler. Vi har även utökat vår kompetens
          med  <a href="https://www.artark.se">AR-T Arkitekter & Ingenjörer</a> som arbetar med arkitektur,
          detaljplaner och bygglov.
        </p>
        <p>
          Vårt team består av totalt 41 personer inom koncernen, alla med olika expertområden och
          färdigheter. Tillsammans kan vi ta på oss en mängd olika byggprojekt, från nybyggnationer
          till renoveringar och ombyggnationer av bostäder, kontor och industrilokaler. Vi strävar
          alltid efter att vara en trygg, långsiktig och ledande partner för våra kunder, och att
          driva utvecklingen framåt i vår bransch.
        </p>
        <p>
          Vi ser våra medarbetare som vår mest betydelsefulla tillgång. Därför strävar vi alltid
          efter att skapa en positiv arbetsmiljö där alla trivs och känner sig trygga. Vi är stolta
          över vårt höga fokus på kvalitet och service, och det är viktigt för oss att alla
          anställda delar vår vision och är engagerade i att uppnå gemensamma mål. Vi tror på
          ärlighet, respekt, solidaritet och lyhördhet som grundläggande principer i allt vi gör,
          och vi uppmuntrar våra medarbetare att dela med sig av sina erfarenheter och bidra till
          ett sammanhållet arbetsklimat.
        </p>
      </Row>
      </section>

       {/* Example projects */}
       <section id="projects">
       <Row className="px-4 my-5">
        <h2>Projekt</h2>
        <p>
          Här följer några exempel på tidigare projekt som vi har genomfört.
        </p>
        <Projects id="projects"/>
      </Row>
      </section>

      {/* Contact */}
      <section id="contact">
      <Row className="px-4 my-5">
        <h2>Kontakt</h2>
        <div>
          Kontakta oss idag för mer information om våra tjänster. Vårt kontor befinner sig på
          Förrådsvägen 8D, 901 32 Umeå.
          Vi har öppet från 09:00 till 17:00, måndag till fredag. Allmänna förfrågningar kan skickas
          till <a href="mailto:info@arbygg.se">info@arbygg.se</a>, eller ring oss.
        </div>
        <Employees/>
      </Row>
      </section>
    </Container>
  );
};

export default Home;
