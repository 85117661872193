import './../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <>
      <footer className="py-5 my-5 bg-primary">
        <Container className="px-4 px-lg-5">
          <p className="m-0 text-center text-white">
            Förrådsvägen 8D &#8729;  901 32 Umeå &#8729; info@arbygg.se <br />
            Orgnr: 556216-5091 <br />
            &copy; 2023 AR Bygg AB <br />
            Den här webbsidan använder inga cookies.
          </p>
        </Container>
      </footer>
    </>
  )
};

export default Footer;
