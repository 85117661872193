import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Data from "../resources/employees.json";
import Row from 'react-bootstrap/Row';


const Employees = () => {
  const nCols = 3;
  return (
    <Row xs={2} md={nCols} className="g-1">
      { Data.map(post =>
          <Col key={post.id} className="col-md-3">
            <Card className="h-100">
              {/* <Image className="px-2 py-2" roundedCircle variant="top" src={post.image}/> */}
              <Card.Body>
                <Card.Title>{post.name}</Card.Title>
                <Card.Text>
                  {post.title}<br />
                  {post.email}<br />
                  {post.mobile ?? ""}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )
      }
    </Row>
  )
}

export default Employees;
